import { CloseFunction, ToastQueue } from '@diallink-corp/convergo-react-toast';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Use the context as a global ref to avoid multiple ping requests.
const HealthContext = createContext({
  healthy: true,
  abortController: new AbortController(),
  timeout: -1
});

const reason = new Error('Request aborted');
reason.name = 'AbortError';

export function usePhoneSystemServerHealth() {
  const context = useContext(HealthContext);
  const [, setHealthy] = useState(true);

  async function check() {
    context.abortController?.abort(reason);
    context.abortController = new AbortController();

    try {
      const { ok, status } = await fetch('/api/ping', {
        signal: context.abortController.signal
      });

      if ([502, 503, 504, 429].includes(status)) {
        context.healthy = false;
        setHealthy(false);
      } else if (ok) {
        context.healthy = true;
        setHealthy(true);
      }
    } catch (error) {
      // Ignore errors;
      return;
    }
  }

  useEffect(() => {
    const ms = 30_000;

    async function callback() {
      await check();
      window.clearTimeout(context.timeout);
      context.timeout = window.setTimeout(callback, ms);
    }

    callback();

    return function cleanup() {
      window.clearTimeout(context.timeout);
    };
  }, []);

  return { healthy: context.healthy };
}

export function ServerHealthMonitor() {
  const { t } = useTranslation('common');

  const [toast, setToast] = useState<{ close: CloseFunction }>();

  const { healthy } = usePhoneSystemServerHealth();

  useEffect(() => {
    if (healthy) {
      return toast?.close();
    }

    if (!toast) {
      setToast({ close: ToastQueue.neutral(t('servers-seem-busy')) });
    }
  }, [healthy, t, toast]);

  return null;
}
