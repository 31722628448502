import { Role } from '~/services/hub/models/Role';
import { Subscription } from '~/services/hub/models/Subscription';
import { SubscriptionItem } from '~/services/hub/models/SubscriptionItem';
import { Team } from '~/services/hub/models/Team';

export enum ColorScheme {
  SYSTEM = 'SYSTEM',
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export function isColorScheme(value: any): value is ColorScheme {
  return value === 'SYSTEM' || value === 'LIGHT' || value === 'DARK';
}

export enum Scale {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export function isScale(value: any): value is Scale {
  return value === 'SMALL' || value === 'MEDIUM' || value === 'LARGE';
}

export type User = {
  avatarUrl?: string;
  email: string;
  firstName: string;
  hasVerifiedEmail: boolean;
  hasVerifiedPhoneNumber: boolean;
  oneTimePassword?: string;
  id: string;
  isAdmin: boolean;
  isOwner: boolean;
  jobTitle?: string;
  language: 'en-US' | 'es-MX' | 'de-DE';
  lastName: string;
  password?: string;
  oneTimeCode?: string;
  phoneNumber?: string;
  roles: Role[];
  teams: Team[];
  useTwoFa: boolean;
  subscriptions?: Subscription[];
  subscriptionItems: SubscriptionItem[];
  colorScheme: ColorScheme;
  scale: Scale;
  createdAt: number;
  paymentConfirmationId?: string;
  paymentConfirmationUrl?: string;
};
